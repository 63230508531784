<template>
  <vs-card class="mt-base">
    <div slot="header">
      <p style="cursor: pointer;" @click="goBackToList">
        <feather-icon icon="ChevronLeftIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer"></feather-icon>Admin List
      </p>
    </div>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="profileImage">Profile Image</label>
          <br />
          <a
            id="profileImage"
            v-if="userDetail.profileImage"
            :href="userDetail.profileImage"
            target="_blank"
          >
            <vs-avatar size="100px" :src="userDetail.profileImage" id="profileImage" />
          </a>
          <vs-avatar size="110px" :src="defaultPP" v-else />
        </div>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">First Name</label>
          <p id="firstName">{{ userDetail.firstName }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Last Name</label>
          <p id="lastName">{{ userDetail.lastName }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Email</label>
          <p id="email">{{ userDetail.email }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="contactNumber">contactNumber</label>
          <p id="contactNumber">{{ userDetail.contactNumber }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="status">status</label>
          <p id="status">{{ userDetail.status }}</p>
        </div>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  name: "SubFranchiseUserDetail",
  data: () => ({
    defaultPP: require("@/assets/images/profile/default.png"),
    userDetail: {}
  }),
  methods: {
    goBackToList() {
      this.$router.push("/franchise/admin");
    }
  },
  async created() {
    this.$vs.loading();
    this.$store
      .dispatch("general/getUserDetailById", this.$route.params.franchiseId)
      .then(response => {
        this.userDetail = response.data.data;
        this.$vs.loading.close();
      })
      .catch(err => {
        this.$vs.loading.close();
      });
  }
};
</script>

<style>
p {
  font-size: 15px;
  font-weight: 500;
}

label {
  font-size: 0.8rem;
}

div.vs-con-textarea {
  margin-top: 0 !important;
}
</style>
